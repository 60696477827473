<template>
  <div>
    <section class="tables">
      <div class="row">
        <div class="col-lg-12">
          <Breadcrumb :breadcrumbs="breadcrumbs" />
        </div>
        <div class="col-lg-12">
          <vue-bootstrap4-table
            :rows="rows"
            :columns="columns"
            :config="config"
            @on-change-query="onChangeQuery"
            @refresh-data="onRefreshData"
            :total-rows="total_rows"
            :classes="classes"
            :show-loader="showLoader"
          >
            <template slot="global-search-clear-icon">
              <i class="mdi mdi-account-search"></i>
            </template>

            <template slot="refresh-button-text">
              <i class="mdi mdi-sync-alert"></i> Refresh
            </template>
            <template slot="reset-button-text">
              <i class="mdi mdi-broom"></i> Reset filters
            </template>

            <template slot="user" slot-scope="props">
              <div class="d-flex align-items-center">
                <b-avatar
                  class="mr-2"
                  variant="primary"
                  :src="props.row.user.ProfilePic"
                ></b-avatar>
                <span class="mr-auto"
                  >{{ props.row.user.firstname }} {{ props.row.user.lastname }}
                  <br />
                  {{ props.row.user.phone }}</span
                >
              </div>
            </template>
            <template slot="referral" slot-scope="props">
              <div class="d-flex align-items-center">
                <b-avatar
                  class="mr-2"
                  variant="primary"
                  :src="props.row.referral.ProfilePic"
                ></b-avatar>
                <span class="mr-auto"
                  >{{ props.row.referral.firstname }}
                  {{ props.row.referral.lastname }}
                  <br />
                  {{ props.row.referral.phone }}</span
                >
              </div>
            </template>

            <template slot="createdAt" slot-scope="props">
              {{
                dateConvert(
                  props.row.createdAt,
                  `${dateFormat.value} ${timeFormat.value}`
                )
              }}</template
            >

            <template slot="payment_status" slot-scope="props">
              <span v-if="props.column.name == 'payment_status'">
                <b-badge
                  variant="success"
                  v-if="props.row.payment_status == 'Completed'"
                  >Completed</b-badge
                >
                <b-badge
                  variant="warning"
                  v-else-if="props.row.payment_status == 'Expired'"
                  >Processing</b-badge
                >
                <b-badge
                  variant="info"
                  v-else-if="props.row.payment_status == 'Pending'"
                  >Pending</b-badge
                >
                <b-badge
                  variant="danger"
                  v-else-if="props.row.payment_status == 'Failed'"
                  >Failed</b-badge
                >
              </span>
            </template>

            <template slot="action" slot-scope="props">
              <span v-if="props.column.name == 'action'">
                <b-button
                  variant="info"
                  size="xs"
                  :href="'#/referrals/' + props.row.user._id"
                  ><span class="text-light">
                    <i class="mdi mdi-eye"></i> Details
                  </span></b-button
                >
              </span>
            </template>
            <template slot="paginataion-previous-button"> Previous </template>
            <template slot="paginataion-next-button"> Next </template>
            <template slot="vbt-action-buttons">
              <div class="float-right">
                <b-form inline>
                  <download-excel
                    class="btn btn-success mr-5"
                    :data="excelDownload"
                    :name="excelName"
                  >
                    Excel <i class="mdi mdi-file-excel-box"></i>
                  </download-excel>
                </b-form>
              </div>
            </template>
          </vue-bootstrap4-table>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { referralService } from "../../services";
import Breadcrumb from "../../components/breadcrumb";
import VueBootstrap4Table from "vue-bootstrap4-table";
import downloadExcel from "vue-json-excel";
import moment from "moment-timezone";
import { mapState } from "pinia";
import { useApp } from "../../store/useApp";
import { getDateFormat } from "../../helpers/utils";

export default {
  name: "referrals",
  data() {
    return {
      breadcrumbs: {
        title: "Referral Lists",
        b1: "Manage Referrals",
        b2: "Referrals",
        b3: "Index",
        link: false,
        name: "Referral lists",
      },
      columns: [
        // {
        //   label: "id",
        //   name: "id",
        //   sort: true,
        //   uniqueId: true, // like this
        // },
        {
          label: "Customer Name",
          name: "user",
          sort: true,
        },
        {
          label: "Referral Name",
          name: "referral",
          sort: true,
        },
        {
          label: "Income Earned",
          name: "amount",
          sort: true,
        },
        {
          label: "status",
          name: "payment_status",
          sort: true,
        },
        {
          label: "Created At",
          name: "createdAt",
          sort: true,
        },
        { label: "Action", name: "action", sort: false },
      ],
      rows: [],
      config: {
        server_mode: true, // by default false
        loaderText: "Updating...", // by default 'Loading...'
        pagination: true,
        global_search: {
          placeholder: "Enter search referral",
          visibility: true,
          case_sensitive: false,
          showClearButton: false,
          searchOnPressEnter: false,
          searchDebounceRate: 5000,
        },
        per_page_options: [10, 25, 50, 100],
        highlight_row_hover: true,
        highlight_row_hover_color: "silver",
        card_mode: true,
        preservePageOnDataChange: true,
        show_refresh_button: true,
      },
      classes: {
        table: "table-bordered table-striped",
      },
      queryParams: {
        sort: [],
        filters: [],
        global_search: "",
        per_page: 10,
        page: 1,
      },
      total_rows: 0,
      showLoader: false,
      dropdowns: [],
    };
  },
  components: {
    VueBootstrap4Table,
    downloadExcel,
    Breadcrumb,
  },
  computed: {
    excelDownload() {
      return referralService.transform(this.rows);
    },
    excelName() {
      return "referrals_" + moment().local().unix();
    },
    user() {
      return this.$store.state.auth.user;
    },
    ...mapState(useApp, ["dateFormat", "timeFormat", "defaultCurrency"]),
  },
  methods: {
    dateConvert(createdAt, format) {
      return getDateFormat(createdAt, format);
    },
    onChangeQuery(queryParams) {
      this.queryParams = queryParams;
      this.showLoader = true;
      this.loadItems();
    },
    onRefreshData() {
      this.showLoader = true;
      this.loadItems();
    },
    loadItems() {
      referralService.getAll(this.queryParams).then((response) => {
        this.total_rows = response.data.totalRecords;
        this.rows = response.data.referrals;
        this.showLoader = false;
      });
    },
    enableDropdowns() {
      this.$el
        .querySelectorAll('[data-toggle="dropdown"]')
        .forEach(($dropdownToggle) => {
          const $dropdown = $dropdownToggle.nextElementSibling;
          let isShown = false;

          function setIsShown(state) {
            isShown = state;
            $dropdown.classList.toggle("show", isShown);
          }

          if (!this.dropdowns.includes($dropdown)) {
            this.dropdowns.push($dropdown);

            $dropdownToggle.addEventListener("click", (event) => {
              event.preventDefault();
              setIsShown(!isShown);
            });

            $dropdown.addEventListener("click", (event) => {
              event.preventDefault();
              setIsShown(false);
            });

            $dropdown.clickOutsideEvent = (event) => {
              const isDropdownOrChildren =
                $dropdown === event.target || $dropdown.contains(event.target);
              const isDropdownToggleOrChildren =
                $dropdownToggle === event.target ||
                $dropdownToggle.contains(event.target);

              if (!isDropdownOrChildren && !isDropdownToggleOrChildren) {
                setIsShown(false);
              }
            };
            document.addEventListener("click", $dropdown.clickOutsideEvent);
          }
        });
    },
  },
  mounted() {
    this.loadItems();
  },
  updated() {
    this.enableDropdowns();
  },
  destroyed() {
    this.dropdowns.forEach(($dropdown) => {
      document.removeEventListener("click", $dropdown.clickOutsideEvent);
    });
  },
};
</script>

<style scoped></style>
